<main class="listagem">
    @if (products?.length <= 0) {
        <h4 class="vazio">{{ 'seller.commerce.products.list.empty-list' | translate }}</h4>
    } @else {
        <div class="table">
            <div>
                <section class="d-flex flex-wrap striped hover">
                    <header>
                        <div class="col-1-25 text-center">{{ 'seller.commerce.products.list.table-header.part-number' | translate }}</div>
                        <div class="col-2">{{ 'seller.commerce.products.list.table-header.product' | translate }}</div>
                        <div class="col-2">{{ 'seller.commerce.products.list.table-header.category' | translate }}</div>
                        <div class="col-2 text-end">{{ 'seller.commerce.products.list.table-header.price' | translate }}</div>
                        <div class="col text-center">{{ 'seller.commerce.products.list.table-header.discount' | translate }}</div>
                        <div class="col text-center">{{ 'seller.commerce.products.list.table-header.cashback' | translate }}</div>
                        <div class="col-0-5 text-center">{{ 'seller.commerce.products.list.table-header.actions' | translate }}</div>
                    </header>
                    <ng-scrollbar>
                        @for (product of products; track product) {
                            <div class="linha">
                                <div class="col-1-25 text-center">{{product.partNumber}}</div>
                                <div class="col-2">{{product.partName}}</div>
                                <div class="col-2">
                                    <span class="category">{{('seller.commerce.products.categories.' + product.category) | translate}}</span>
                                </div>
                                <div class="col-2 text-end">
                                    @if (product.discount > 0) {
                                        <div class="prices">
                                            {{product.price | currency:'R$'}}
                                            <i-feather name="arrow-right" class="arrow-right"></i-feather>
                                            <span class="price-with-discount">{{product.priceWithDiscount | currency:'R$'}}</span>
                                        </div>
                                    } @else {
                                        {{product.price | currency:'R$'}}
                                    }
                                </div>
                                <div class="col text-center">
                                    @if (product.discount > 0) {
                                        <span class="discount">{{product.discount}}%</span>
                                    } @else {
                                        --
                                    }
                                </div>
                                <div class="col text-center">
                                    @if (product.cashback > 0) {
                                        <span class="cashback">{{product.cashback | number: ( product.cashback < 10 ? '.0-2' : '0.0-1')}}%</span>
                                    } @else {
                                        --
                                    }
                                </div>
                                <div class="col-0-5 text-center actions">
                                    <i-feather name="edit" class="edit" (click)="selectProductToEdit(product)"></i-feather>
                                </div>
                            </div>
                        }
                    </ng-scrollbar>
                    <footer>
                        <mat-paginator
                                    [showFirstLastButtons]="true"
                                    [hidePageSize]="true"
                                    [length]="totalProducts"
                                    [pageSize]="10"
                                    [pageIndex]="pageNumberController"
                                    (page)="changePage($event)">
                        </mat-paginator>
                    </footer>
                </section>
            </div>
        </div>
    }
</main>
