import { Component, OnInit, ViewChild } from '@angular/core';
import { SellerCommerceEditAllComponent } from './_components/edit-all/edit-all.component';
import { SellerCommerceService } from './produtos-commerce.service';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { GetByPageResponse } from './_models/getByPageResponse';
import { Product } from './_models/product';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'app/_services/notification.service';
import { Globals } from 'app/_models/globals';
import { Categoria } from 'app/buyer/mercado-partsfy/list/_models/Categoria';

declare const isEmpty: any;
declare const sort_by: any;
@Component({ selector: 'app-produtos-commerce', templateUrl: './produtos-commerce.component.html', styleUrls: ['./produtos-commerce.component.scss'] })
export class SellerCommerceComponent {
    _searchString: string = '';
    isList: boolean = true;
    isLoading: boolean = false;

    actualPage: number = 0;
    categories: Categoria[] = [];
    selectedProductToEdit = null;
    totalProducts: number;

    @ViewChild('templateEditAll') public editAllModal: SellerCommerceEditAllComponent;

    products: Product[] = [];

    constructor(private sellerCommerceService: SellerCommerceService, private route: ActivatedRoute, private notificationService: NotificationService, private ngxLoader: NgxUiLoaderService, private globals: Globals) {
    }

    ngOnInit() {
        let urlSegments = this.route.snapshot.url;
        let param_new_product = urlSegments.some(segment => segment.path === 'new');
        let param_id = this.route.snapshot.params.id;
        this.getCategories();

        if(!isEmpty(param_id))
            return this.getById(param_id);

        this.getByPage(0, param_new_product);
    }

    createProduct(product: Product) {
        this.ngxLoader.startLoader('loader-principal');

        this.sellerCommerceService.createProduct(product).subscribe({
            next: (response: ApiResponseModel<Product>) => {
                this.ngxLoader.stopLoader('loader-principal');

                this.getByPage(0);
                this.goBackToList();
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.isLoading = false;
                this.notificationService.showErrorToastr(this.globals.translate('seller.commerce.products.messages.error.creation'));
            }
        })
    }

    editAll(cashback: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.sellerCommerceService.editAll(cashback).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                this.ngxLoader.stopLoader('loader-principal');

                if (response.success && response.result) {
                    this.products.forEach((product) => {
                        product.cashback = cashback;
                    });

                    this.editAllModal.close();
                }
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.notificationService.showErrorToastr(this.globals.translate('seller.commerce.products.messages.error.edit-all'));
            }
        })
    }

    getById(productId: string) {
        this.ngxLoader.startLoader('loader-principal');

        this.sellerCommerceService.getById(productId).subscribe({
            next: (response: ApiResponseModel<Product>) => {
                let product = response.result;
                this.openProductEdition(product);

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.notificationService.showErrorToastr(this.globals.translate('seller.commerce.products.messages.error.get-one'));
                this.goBackToList();
            }
        });
    }

    getByPage(pageNumber: number, isNewProduct: boolean = false) {
        this.ngxLoader.startLoader('loader-principal');

        this.sellerCommerceService.getByPage(pageNumber, this._searchString.toLowerCase()).subscribe({
            next: (response: ApiResponseModel<GetByPageResponse>) => {
                this.actualPage = pageNumber;
                this.totalProducts = response.result.totalProducts;
                this.products = response.result.products;
                this.products.forEach(product => this.updateProductTotal(product));

                if (isNewProduct)
                    this.openProductCreation();

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.notificationService.showErrorToastr(this.globals.translate('seller.commerce.products.messages.error.list'));
            }
        });
    }

    getCategories() {
        this.sellerCommerceService.getCategories().subscribe({
            next: (response: ApiResponseModel<Categoria[]>) => {
                this.categories = response.result.sort(sort_by([{ name: 'name' }]));
            },
            error: (error) => {
                console.log(error)
                this.ngxLoader.stopLoader('loader-principal');
            }
        })
    }

    goBackToList() {
        if (this.products.length == 0)
            this.getByPage(0);

        this.isList = true;
        this.selectedProductToEdit = null;
        history.pushState({}, '', '/vendedor/commerce');
    }

    handleProductSave(product: Product) {
        if (product.productId)
            return this.saveProduct(product);

        return this.createProduct(product);
    }

    openProductCreation() {
        this.isList = false;
        history.pushState({}, '', '/vendedor/commerce/new');
    }

    openProductEdition(product: Product) {
        this.selectedProductToEdit = product;
        history.pushState({}, '', '/vendedor/commerce/edit/' + product.productId);
        this.isList = false;
    }

    saveProduct(product: Product) {
        this.ngxLoader.startLoader('loader-principal');

        this.sellerCommerceService.editProduct(product).subscribe({
            next: (response: ApiResponseModel<Product>) => {
                this.ngxLoader.stopLoader('loader-principal');

                this.getByPage(0);
                this.goBackToList();
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.isLoading = false;
                this.notificationService.showErrorToastr(this.globals.translate('seller.commerce.products.messages.error.edit'));
            }
        })
    }

    updateProductTotal(product: Product) {
        if (!product.discount) {
            product.priceWithDiscount = null;
            return;
        }

        product.priceWithDiscount = product.price - (product.price * product.discount/100);
    }

    get searchTerm(): string {
        return this._searchString;
    }

    set searchTerm(value: string) {
        this._searchString = value;
        this.getByPage(0);
    }
}
